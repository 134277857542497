import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import DownloadsSection from "components/downloads-sections"
import { ExternalButtonLink } from "components/buttons"
import { Button } from "components/buttons"

// Training Materials
import TrainingPresentation from "assets/downloads/visby-university/sexual-health-test/Visby-Medical-Sexual-Health-Training-Presentation.pdf"
import ImplementationBinder from "assets/downloads/visby-university/sexual-health-test/Visby-Medical-Sexual-Health-Implementation-Binder.pdf"
import LabStickers from "assets/downloads/visby-university/sexual-health-test/Visby_Supplies_Labels.pdf"
import Checklist from "assets/downloads/visby-university/sexual-health-test/VM_Results_STI_PL-000218-A.pdf"
import ExternalControlLog from "assets/downloads/visby-university/sexual-health-test/Visby-Medical-Sexual-Health-External-Control-Log.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 0;

  ${ExternalButtonLink} {
	margin-bottom: 30px;
	width: 100%; 
	color: ${colors.charcoal}!important;
  
	${breakpoint.small`
		width: auto; 
	`}

	&:hover {
		color: ${colors.white}!important;
	}
  }
`

class StiEndUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			section: ""
		}
	}

	handleSectionChange = (selSection) => {
		const inactiveSections = document.querySelectorAll("[data-filter='sexual-health'][data-section]")
		inactiveSections.forEach(section => {
			section.classList.remove("visibleBlock")
		})
		const activeSections = document.querySelectorAll("[data-section='" + selSection + "']")
		activeSections.forEach(section => {
			section.classList.add("visibleBlock")
		})
	}

	render = props => (
		<DownloadsSection
			id="sti-end-user"
			data-filter="sexual-health" data-filter-type="devices" data-section="enduser"
			style={{ paddingBottom: "25px" }}
		>
			<StyledContainer>
				<div className="block__content">
					<h3>End User Training<span className="subtitle"> -- Resources for individuals operating the Visby test</span></h3>
				</div>
				<div className="block">
					<div className="block__material">
						<ul className="downloadsList">
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Point-of-Care-Quick-Reference-Guide.pdf`} target="_blank" rel="noopener noreferrer">Quick Reference Guide</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Point-of-Care-Instructions-for-Use.pdf`} target="_blank" rel="noopener noreferrer">Instructions for Use</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Specification-Sheet.pdf`} target="_blank" rel="noopener noreferrer">Specification Sheet</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Safety-Data-Sheet-Collection-Media.pdf`} target="_blank" rel="noopener noreferrer">Safety Data Sheet - Collection Media</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Device-Safety-Data-Sheet-Solid-Reagents.pdf`} target="_blank" rel="noopener noreferrer">Device Safety Data Sheet – Solid Reagents</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Device-Safety-Data-Sheet-Liquid-Reagents.pdf`} target="_blank" rel="noopener noreferrer">Device Safety Data Sheet – Liquid Reagents</a>
							</li>
						</ul>
						<ExternalButtonLink href="https://doodle.com/bp/1514066645/lizanaya" target="_blank" rel="noopener noreferrer" backgroundcolor="orange">Schedule End User Training</ExternalButtonLink>
					</div>
					<div className="block__material">
						<ul className="downloadsList">
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions-for-Use.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions for Use</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions—English</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions-Spanish.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions—Spanish</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions-Chinese.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions—Chinese</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions-Mandarin.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions—Mandarin</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions-Japanese.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions—Japanese</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions-Russian.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions—Russian</a>
							</li>
						</ul>
					</div>
				</div>

				<div className="block">
					<div className="block__material">
						<h4>Training Materials</h4>
						<div className="thumbnails_row">
							<div className="image_column">
								<a href={TrainingPresentation} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/training-sexual-health.png"
										width={350}
										alt="Training Presentation"
										className="thumbnail_image"
									/>
								</a>
								<p><a href={TrainingPresentation} target="_blank" rel="noopener noreferrer">Training Presentation</a></p>
							</div>
							<div className="image_column">
								<a href={ImplementationBinder} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/binder.jpg"
										width={350}
										alt="Implementation Binder"
										className="thumbnail_image"
									/>
								</a>
								<p><a href={ImplementationBinder} target="_blank" rel="noopener noreferrer">Implementation Binder</a></p>
							</div>
							<div className="image_column">
								<a href={ExternalControlLog} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/sexual-health-test/sti-external-log.jpg"
										width={350}
										alt="Visby STI-Detailed External Control Log"
										className="thumbnail_image"
									/>
								</a>
								<p><a href={ExternalControlLog} target="_blank" rel="noopener noreferrer">Visby STI-Detailed External Control Log</a></p>
							</div>
							<div className="image_column">
								<a href="https://docs.google.com/forms/d/e/1FAIpQLSeuvLyTeK_RvA1p9ExPtfwHysAR2QkMUO1g1q4DQK5GTOPrrw/viewform" target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/quiz-sexual-health.jpg"
										width={350}
										alt="Visby STI Training Quiz"
										className="thumbnail_image"
									/>
								</a>
								<p><a href="https://docs.google.com/forms/d/e/1FAIpQLSdFSL7IdE-5tKQlYSalIFyghNzaH9kk7Q-PUwnzQFSNIo9tHA/viewform" target="_blank" rel="noopener noreferrer">Visby STI Training Quiz</a><br /><i>(With Certificate upon completion)</i></p>
							</div>
							<div className="image_column">
								<a href={`/Visby-STI-CLSI-TM-000017A.zip`} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/clsi-template-sti.jpg"
										width={350}
										alt="CLSI Template"
										className="thumbnail_image"
									/>
								</a>
								<p><a href={`/Visby-STI-CLSI-TM-000017A.zip`} target="_blank" rel="noopener noreferrer">CLSI Template</a></p>
							</div>
							<div className="image_column">
								<a href={LabStickers} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/sexual-health-test/devices.png"
										width={350}
										alt="Visby Lab Stickers"
										className="thumbnail_image"
									/>
								</a>
								<p><a href={LabStickers} target="_blank" rel="noopener noreferrer">Visby Lab Stickers*</a></p>
							</div>
							<div className="image_column">
								<a href={Checklist} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/sexual-health-test/sti-results-template.png"
										width={350}
										alt="Visby Results Template"
										className="thumbnail_image"
									/>
								</a>
								<p><a href={Checklist} target="_blank" rel="noopener noreferrer">Visby Results Template</a></p>
							</div>
						</div>
						<p className="formsNote">*The Visby Lab Stickers can be ordered via the complimentary supporting material form:</p>
						<Button className="orangeButton" onClick={() => this.handleSectionChange("orderforms")}>Order Forms</Button>
					</div>
				</div>
			</StyledContainer>
		</DownloadsSection>
	)
}

export default StiEndUser
